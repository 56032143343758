import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../characters.scss';
import './guides.scss';
import { IReverseCharacter } from '../../../modules/common/model/graphql-types';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import { FilterBar } from '../../../modules/cs/common/components/filter-bar/filter-bar';
import lodash from 'lodash';
import { RevTagsArray } from '../../../modules/reverse/common/components/rev-tags';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IReverseCharacterNodes {
  nodes: IReverseCharacter[];
}

interface IReverseCharacterEntry {
  allCharacters: IReverseCharacterNodes;
}

interface IProps {
  data: IReverseCharacterEntry;
}

const ReversePsychubeList: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const FILTER_STORAGE_KEY = 'reverse-characters-psych-filter';
  const OPTS_STORAGE_KEY = 'reverse-characters-psych-opts';

  const isBrowser = typeof window !== 'undefined';
  let currentOpts = isBrowser
    ? JSON.parse(window.sessionStorage.getItem(OPTS_STORAGE_KEY))
    : {};
  if (!currentOpts) {
    currentOpts = {};
  }

  const [activeFilters, setActiveFilters] = useState(
    isBrowser
      ? JSON.parse(window.sessionStorage.getItem(FILTER_STORAGE_KEY))
      : {} || {}
  );
  const [sortOption, setSortOption] = useState(currentOpts.sort || null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '2✦', value: '2' },
          { label: '3✦', value: '3' },
          { label: '4✦', value: '4' },
          { label: '5✦', value: '5' },
          { label: '6✦', value: '6' }
        ]
      },
      {
        key: 'afflatus',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Star',
            tooltip: 'Star',
            image: (
              <StaticImage
                src="../../../images/reverse/icons/afl_star.png"
                width={24}
                alt="Star"
              />
            )
          },
          {
            value: 'Plant',
            tooltip: 'Plant',
            image: (
              <StaticImage
                src="../../../images/reverse/icons/afl_plant.png"
                width={24}
                alt="Plant"
              />
            )
          },
          {
            value: 'Mineral',
            tooltip: 'Mineral',
            image: (
              <StaticImage
                src="../../../images/reverse/icons/afl_mineral.png"
                width={24}
                alt="Mineral"
              />
            )
          },
          {
            value: 'Beast',
            tooltip: 'Beast',
            image: (
              <StaticImage
                src="../../../images/reverse/icons/afl_beast.png"
                width={24}
                alt="Beast"
              />
            )
          },
          {
            value: 'Spirit',
            tooltip: 'Spirit',
            image: (
              <StaticImage
                src="../../../images/reverse/icons/afl_spirit.png"
                width={24}
                alt="Spirit"
              />
            )
          },
          {
            value: 'Intellect',
            tooltip: 'Intellect',
            image: (
              <StaticImage
                src="../../../images/reverse/icons/afl_intellect.png"
                width={24}
                alt="Intellect"
              />
            )
          }
        ]
      },
      {
        key: 'damageType',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Mental',
            label: 'Mental'
          },
          {
            value: 'Reality',
            label: 'Reality'
          }
        ]
      },
      { key: 'tags', type: 'dropdown', values: RevTagsArray },
      { key: 'reset', type: 'reset' }
    ],
    defaults: isBrowser
      ? JSON.parse(window.sessionStorage.getItem(FILTER_STORAGE_KEY)) || {}
      : {}
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.afflatus && activeFilters.afflatus !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.afflatus.toLowerCase() === activeFilters.afflatus.toLowerCase()
        );
      }
      if (activeFilters.damageType && activeFilters.damageType !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.damageType &&
            emp.damageType.length > 0 &&
            emp.damageType.indexOf(activeFilters.damageType) > -1
        );
      }
      if (activeFilters.tags && activeFilters.tags !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.tags &&
            emp.tags.length > 0 &&
            emp.tags.indexOf(activeFilters.tags) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.slug > b.slug ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.slug < b.slug ? 1 : -1));
    }

    if (isBrowser) {
      window.sessionStorage.setItem(
        FILTER_STORAGE_KEY,
        JSON.stringify(activeFilters || {})
      );
      const newOpts = {
        sort: sortOption
      };
      window.sessionStorage.setItem(
        OPTS_STORAGE_KEY,
        JSON.stringify(newOpts || {})
      );
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption, isBrowser]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={
        'generic-page characters-page-nikke characters-reverse r99-guide'
      }
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Psychubes Cheat sheet</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_psycheat.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Reverse: 1999 Psychubes Cheat sheet</h1>
          <h2>
            A guide that will help you pick the best Psychubes for your
            characters in Reverse:1999.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Psychubes Cheat sheet" />
      <div className="employees-filter-bar">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="psychube-row">
        {visibleCharacters.map((emp, index) => {
          return (
            <div className="psychube-row-single" key={index}>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug={emp.slug}
                  mode="icon"
                  showLabel
                  enablePopover
                />
              </Card>
              <div className="tubes-details">
                {emp.psychubeSuggested ? (
                  <>
                    <h5 className={emp.afflatus}>Psychube suggestions</h5>
                    <div className="tubes-suggestions">
                      {emp.psychubeSuggested.map((tube, index2) => {
                        return (
                          <span key={index2}>
                            <Card className="avatar-card">
                              <Reverse1999Psychube
                                slug={tube.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                              />
                            </Card>
                          </span>
                        );
                      })}
                    </div>
                    <h5 className={emp.afflatus}>Psychube comments</h5>
                    <div className="comments">
                      {emp.psychubeComments ? (
                        <>
                          <div className={`comments-inside`}>
                            {renderRichText(emp.psychubeComments, options)}
                          </div>
                        </>
                      ) : (
                        <p className="no-comments">
                          Currently there are no comments available for this
                          character.
                        </p>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="no-info">
                    <p>
                      Currently there are no Psychube suggestions available for
                      this character.
                    </p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../../images/reverse/no_results.png"
            alt="No results"
          />
          <p>No characters found. Try changing your filter.</p>
        </div>
      )}
      <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ReversePsychubeList;

export const Head: React.FC = () => (
  <Seo
    title="Psychubes Cheat sheet | Reverse: 1999 | Prydwen Institute"
    description="A guide that will help you pick the best Psychubes for your characters in Reverse:1999."
    game="reverse"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulReverseCharacter(
      sort: { fields: slug, order: ASC }
    ) {
      nodes {
        ...ReverseCharacterFieldsFragment
      }
    }
  }
`;
